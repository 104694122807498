import { createSharedComposable } from '@/features/base/createSharedComposable';
import { TelegramWebApp } from '@/types/TelegramWebApp';
import { shallowRef, unref } from 'vue';
import { checkTelegramLink } from '@/helpers/checkTelegramLink';

export const sharedTelegramWebApp = createSharedComposable(() => {
  const telegramWebApp = (window as any).Telegram!.WebApp as TelegramWebApp;
  const isTelegramWebApp = Boolean(telegramWebApp.initData);
  const isShowTelegramNativeButton = shallowRef(true);
  const webAppButtonIsLoading = shallowRef(false);
  const webAppButtonName = shallowRef('');
  const webAppButtonVisible = shallowRef(false);
  const webAppButtonDisable = shallowRef(false);
  let lastOnClickListener: (() => void) | undefined;

  /** METHODS */
  const webAppButtonSetVisible = (value: boolean) => {
    webAppButtonVisible.value = value;

    if (unref(isShowTelegramNativeButton)) {
      if (value) {
        telegramWebApp.MainButton.show();
      } else {
        telegramWebApp.MainButton.hide();
      }
    }
  };
  const webAppButtonSetDisable = (value: boolean) => {
    webAppButtonDisable.value = value;

    if (value) {
      telegramWebApp.MainButton.disable();
    } else {
      telegramWebApp.MainButton.enable();
    }
  };
  const webAppButtonSetLoading = (value: boolean) => {
    webAppButtonIsLoading.value = value;
    if (value) {
      webAppButtonSetDisable(true);
      telegramWebApp.MainButton.showProgress(false);
    } else {
      webAppButtonSetDisable(false);
      telegramWebApp.MainButton.hideProgress();
    }
  };
  const webAppButtonOffClick = () => {
    const cb = lastOnClickListener;
    if (cb) {
      lastOnClickListener = () => undefined;
      telegramWebApp.MainButton.offClick(cb);
    }
  };
  const webAppButtonOnClick = (cb: () => void) => {
    webAppButtonOffClick();
    lastOnClickListener = cb;
    telegramWebApp.MainButton.onClick(cb);
  };
  const webAppButtonClick = () => {
    lastOnClickListener?.();
  };
  const webAppSetButtonName = (name: string) => {
    webAppButtonName.value = name;
    telegramWebApp.MainButton.setText(name);
  };
  const webAppButtonHide = () => {
    webAppButtonSetVisible(false);
  };
  const webAppButtonShow = () => {
    webAppButtonSetVisible(true);
  };
  const webAppUpdateButton = ({
    name,
    cb,
  }: {
    name: string
    cb?: () => void
  }) => {
    telegramWebApp.expand();
    webAppButtonSetLoading(!cb);
    webAppButtonShow();
    webAppSetButtonName(name);
    if (cb) {
      webAppButtonOnClick(cb);
    } else {
      webAppButtonOffClick();
    }
  };
  const webAppRequestUserAction = ({
    message = 'Продолжить',
    cb,
  }: {
    message?: string,
    cb: () => void
  }) => {
    try {
      telegramWebApp.showPopup({
        message,
        buttons: [{
          type: 'ok',
          text: 'Ок',
        }],
      }, cb);
    } catch (e) {
      webAppUpdateButton({
        name: message,
        cb,
      });
    }
  };
  const webAppOpenLink = (link?: string) => {
    if (!link) {
      return;
    }
    if (checkTelegramLink(link) && ![
      'macos',
      'windows',
    ].includes(telegramWebApp.platform)) {
      telegramWebApp.openTelegramLink(link);
    } else {
      telegramWebApp.openLink(link);
    }
  };
  const webAppWindowsOpen = (url: string, ...args: any[]) => {
    webAppOpenLink(url);

    return {
      focus: () => {
        //
      },
    };
  };

  return {
    telegramWebApp,
    isShowTelegramNativeButton,
    webAppButtonName,
    isTelegramWebApp,
    webAppButtonIsLoading,
    webAppButtonVisible,
    webAppButtonDisable,
    webAppButtonClick,
    webAppSetButtonName,
    webAppUpdateButton,
    webAppButtonSetLoading,
    webAppButtonOffClick,
    webAppButtonOnClick,
    webAppOpenLink,
    webAppWindowsOpen,
    webAppRequestUserAction,
    webAppButtonHide,
    webAppButtonShow,
  };
});
